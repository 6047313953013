import './_banner-block.scss'

const bannerBlock = function() {

};

document.addEventListener('DOMContentLoaded', function(){
    bannerBlock()
}, false);

window.addEventListener('acf/loaded/banner-block', function (e) {
    bannerBlock()
});